<iwt-estatecloud-main-layout>
  <div class="mb-4 flex bg-gray-100 rounded-2xl border p-4">
    <div class="flex flex-1 items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2.0"
        stroke="currentColor"
        class="key_icon"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
        />
      </svg>
      <empro-typography type="base"> Lizenz erstellen</empro-typography>
    </div>
  </div>

  <div class="p-4 bg-white rounded-xl shadow-md space-y-4">
    <div class="md:flex md:justify-between gap-4">
      <div class="md:w-1/3 md:border-r-[1px] pr-4 pb-8">
        <div class="label">
          <empro-typography type="base">Produktversion</empro-typography>
        </div>
        <empro-select
          class="w-60 dropdown-content"
          id="productversion"
          [fieldControl]="formGroup.controls.commonProductId"
          [options]="commonProductIdOptions"
          [sortOptions]="false"
        ></empro-select>
      </div>
      <div class="md:w-1/3 md:border-r-[1px] pr-4 pb-8">
        <div class="label">Lizenzart</div>
        <div class="mt-2 licenseTypeRadios">
          @for (radio of getLicenseTypeRadios(); track radio.id) {
          <div class="flex items-center mb-2">
            <label class="inline-flex items-center">
              <input
                type="radio"
                class="mr-2"
                name="licenseType"
                [value]="radio.value"
                [formControl]="radio.control"
              />
              {{ radio.label }}
            </label>
            @if(radio.countControl) {
            <empro-shared-input
              id="count"
              class="ml-4 w-10"
              [fieldControl]="radio.countControl"
            />
            }
          </div>
          }
        </div>
      </div>
      <div class="md:w-1/3">
        <div class="label">
          <empro-typography type="base">Erwerb</empro-typography>
        </div>
        <div class="mt-2">
          @for (radio of getAcquisitionTypeRadios(); track radio.id) {
          <div class="flex items-center mb-2">
            <label class="inline-flex items-center">
              <input
                type="radio"
                class="mr-2"
                name="acquisitionType"
                [value]="radio.value"
                [formControl]="radio.control"
              />
              {{ radio.label }}
            </label>
          </div>
          }
          <div class="mt-8 border-t-[1px] pt-4">
            <div class="label">
              <empro-typography type="base">Ablauf</empro-typography>
            </div>
            <empro-shared-datepicker
              id="validFrom"
              [fieldControl]="formGroup.controls.expirationDate"
              [value]="formGroup.controls.expirationDate"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="border-solid pt-4 border-t-[1px]">
      <div class="label">
        <empro-typography type="base" weight="bold"
          >Informationen des Kunden</empro-typography
        >
      </div>
      <div class="md:flex md:justify-between gap-4">
        <div class="md:w-1/3 md:border-r-[1px] pr-4 pb-8">
          <div>
            <div class="label">
              <empro-typography type="base"
                >*DCDB Kundenkennung</empro-typography
              >
            </div>
            <input
              type="text"
              class="w-30"
              [formControl]="formGroup.controls.hexCustomerId"
            />
            @if(formGroup.get('hexCustomerId')?.touched &&
            formGroup.get('hexCustomerId')?.errors) {
            <span class="error">DCDB Kundenkennung muß angegeben werden</span>
            }
          </div>
        </div>
        <div class="md:w-1/3 md:border-r-[1px] pr-4 pb-8">
          <div class="label">
            <empro-typography type="base">*Hardwarekennung</empro-typography>
          </div>
          <div class="flex justify-between">
            <input
              type="text"
              class="w-30"
              [formControl]="formGroup.controls.hWSerial1"
            />
            <input
              type="text"
              class="ml-4 w-30"
              [formControl]="formGroup.controls.hWSerial2"
            />
            <input
              type="text"
              class="ml-4 w-30"
              [formControl]="formGroup.controls.hWSerial3"
            />
          </div>
          @if(checkHardwareSerialCode()) {
          <span class="error">Hardwarekennung muß angegeben werden</span>
          }
        </div>
        <div class="md:w-1/3 relative">
          <div class="label">
            <empro-typography type="base">Freischaltcode</empro-typography>
          </div>
          <div class="flex justify-between h-4"></div>
          @if(freeCode !== '') {
          <div>
            <empro-typography type="h6" weight="regular">
              @if(formGroup.controls.freeCode1.value !=='') {
              <empro-button
                [fill]="'solid'"
                [variant]="'primary'"
                class="copyIcon"
                (click)="copyFreecode()"
                onKeyDown="onKeyDown()"
                tabindex="0"
              >
                <empro-shared-icon-new name="document-duplicate" />
              </empro-button>
              } {{ freeCode }}
            </empro-typography>
          </div>
          } @else {
          <div>
            <empro-typography type="base">
              Freischaltcode wird erst nach dem Speichern angezeigt
            </empro-typography>
          </div>
          }
        </div>
      </div>
      <div class="border-t-[1px] pt-8 mt-8">
        <div class="label">
          <empro-typography type="base">Notizen</empro-typography>
        </div>
        <textarea
          class="w-full border-[1px] border-solid border-gray-300 rounded-md"
          rows="4"
          [formControl]="formGroup.controls.notes"
        ></textarea>
      </div>
      <div class="flex justify-between mt-6">
        <div class="max-w-xs">
          <empro-button
            [disabled]="buttonDisabled()"
            variant="primary"
            fill="solid"
            (onClick)="onSubmit()"
            class="font-medium"
          >
            Freischaltcode erzeugen
          </empro-button>
        </div>

        <div class="max-w-xs">
          <empro-button
            (onClick)="cancel()"
            variant="primary"
            fill="solid"
            class="font-medium"
          >
            Abbrechen
          </empro-button>
        </div>
      </div>
    </div>
  </div>
</iwt-estatecloud-main-layout>
