<ec-shared-modal
  [open]="openModal"
  [hasClose]="true"
  [closeOutside]="false"
  (onClose)="closeModal(undefined)"
>
  <ng-container>
    <div modalTitle>
      <empro-shared-typography
        type="h6"
        [classes]="'inline-flex text-subtle'"
        title
      >
        <div class="default-styling">Willkommensmail erneut schicken</div>
      </empro-shared-typography>
    </div>
  </ng-container>
  <div modalContent class="text-left" [ngClass]="'h-full'">
    <div class="mt-4">
      <div>
        <empro-shared-typography [classes]="'inline-flex text-subtle'" title>
          <p>
            <span class="default-styling">
              Willst du für den Account {{ this.currentUser.email }} die
              Zugangsdaten zurücksetzen und erneut die Willkommens-E-Mail
              verschicken?
            </span>
          </p>
        </empro-shared-typography>
      </div>
      <br />
      <hr />
      <div class="flex justify-center items-center gap-x-10 mt-6" modalFooter>
        <empro-button
          class="font-medium"
          [variant]="'secondary'"
          [fill]="'solid'"
          onKeyDown="onKeyDown()"
          (click)="closeModal('resendWelcomeMail')"
        >
          Versenden
        </empro-button>
        <empro-button
          [variant]="'primary'"
          [fill]="'solid'"
          (click)="closeModal('cancel')"
          class="font-medium"
          onKeyDown="onKeyDown()"
        >
          Abbrechen
        </empro-button>
      </div>
    </div>
  </div>
</ec-shared-modal>

<div class="default-styling mt-4" *ngIf="messageShow" id="userListAlterBox">
  <ec-shared-alert-box
    *ngIf="messageShow"
    class="mb-4"
    [variant]="messageType"
    [message]="message"
  ></ec-shared-alert-box>
</div>

<div class="mb-5">
  <div class="relative">
    <div class="flex flex-col">
      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full py-2 sm:px-6">
            <div class="overflow-hidden rounded-t-2xl border sm:-mx-6">
              <table class="min-w-full text-left text-sm font-light bg-white">
                <thead class="border-b font-medium whitespace-nowrap">
                  <tr>
                    <th
                      *ngFor="let column of COLUMNS | keyvalue: originalOrder"
                      scope="col"
                      class="px-6 py-4 cursor-pointer hover:bg-cyan-50"
                    >
                      <div class="flex items-center">
                        {{ column.value }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let user of users.data"
                    class="border-b hover:bg-cyan-50 cursor-pointer"
                  >
                    <td class="whitespace-nowrap px-6 py-4">
                      {{ user.email }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4">
                      {{ user.userUuid }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4">
                      {{ user.enabled }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4">
                      {{ user.lastLoginTime | date: 'dd.MM.yyyy HH:mm:ss' }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4">
                      {{ user.lastLoginChallanges }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4">
                      <empro-shared-typography
                        [classes]="'inline-flex text-subtle'"
                        title
                      >
                        <empro-button
                          [fill]="'solid'"
                          [variant]="'primary'"
                          id="resend-email-button"
                          (click)="showResendEmailModal(user)"
                          [type]="'submit'"
                          onKeyDown="onKeyDown()"
                          [disabled]="
                            usersWhereResentHaveBeenClickedAllready.includes(
                              user
                            ) ||
                            user.email === undefined ||
                            user.email === ''
                              ? true
                              : false
                          "
                        >
                          <span class="default-styling">
                            Willkommens-E-Mail schicken
                          </span>
                        </empro-button>
                      </empro-shared-typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="overflow-hidden rounded-b-2xl border border-t-transparent sm:-mx-6 bg-white p-4"
            >
              <div
                *ngIf="users.totalCount > 0"
                class="flex items-center justify-between mt-4"
              >
                <div
                  class="sm:flex sm:flex-1 sm:items-center sm:justify-between"
                >
                  <div class="relative">
                    <span class="font-medium inline-block mr-4"
                      >{{ users.totalCount }} Ergebnisse</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
