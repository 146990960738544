import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonCustomerView } from '../../../models/common-customer-view';
import { CustomerRequest } from '../../../models/customer-request';
import { CustomerResponse } from '../../../models/customer-response';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'iwt-estatecloud-common-customer',
  templateUrl: './common-customer.component.html',
  styleUrls: ['./common-customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommonCustomerComponent implements AfterViewInit {
  private editionConfigOptionsMap: Record<
    'Pro' | 'Office' | 'Standalone',
    string[]
  > = {
    Pro: ['Companion'],
    Office: ['Companion'],
    Standalone: ['XS', 'XS-Lexware', 'S'],
  };

  CommonCustomerView = CommonCustomerView;
  @Input() showForm = true;
  _view = CommonCustomerView.EDIT;
  @Input() set view(value: CommonCustomerView) {
    this._view = value;
    this.createCustomerForm.reset();
    this.customer = this._customer;
  }
  get view() {
    return this._view;
  }

  editionConfigOptions: string[] = [];

  _customer: CustomerResponse | undefined;
  @Input() set customer(value: CustomerResponse | undefined) {
    this._customer = value;
    if (value) {
      this.staticCreateForm.controls.tenantUuid.setValue(value['tenantUuid']);
      value['serviceVersion'] &&
        this.staticCreateForm.controls.serviceVersion.setValue(
          value['serviceVersion'],
        );
      this.createCustomerForm.enable();
      if (this.view === CommonCustomerView.EDIT) {
        this.createCustomerForm.controls.customerId.disable();
      }
      Object.entries(value).forEach((el: [string, string | number]) => {
        this.createCustomerForm.get(el[0])?.setValue(el[1]);
      });
    }
  }
  get customer(): CustomerResponse | undefined {
    return this._customer;
  }

  @Output() cancelAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateAction: EventEmitter<CustomerResponse> =
    new EventEmitter<CustomerResponse>();
  @Input() isLoading = false;
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Input() errorMessage = { show: false, message: '' };
  showCreateStandaloneCustomerForm = false;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private ref: ChangeDetectorRef,
  ) {
    this.createCustomerForm.controls.edition.valueChanges.subscribe(
      (value: string | number | null | undefined) => {
        if (value === 'Pro' || value === 'Office' || value === 'Standalone') {
          this.editionConfigOptions = this.editionConfigOptionsMap[value];
          if (
            value === 'Standalone' &&
            this.createCustomerForm.controls.edition.untouched
          ) {
            this.createStandaloneCustomerForm.controls.country.setValue(
              'Deutschland',
            );
          }
        }
        if (value !== 'Standalone') {
          this.createStandaloneCustomerForm.reset();
          this.createCustomerForm.controls.edition.markAsUntouched();
          this.createCustomerForm.controls.editionConfig.disable();
        }
        this.showCreateStandaloneCustomerForm =
          value === 'Standalone' && this.view === CommonCustomerView.CREATE;
        if (value === 'Standalone') {
          this.createCustomerForm.controls.editionConfig.enable();
        }

        switch (this.createCustomerForm.controls.editionConfig.value) {
          case 'S':
            this.createCustomerForm.controls.editionConfig.setValue(
              this.editionConfigOptions[2],
            );
            break;
          case 'XS-Lexware':
            this.createCustomerForm.controls.editionConfig.setValue(
              this.editionConfigOptions[1],
            );
            break;
          case 'XS':
            this.createCustomerForm.controls.editionConfig.setValue(
              this.editionConfigOptions[0],
            );
            break;
          default:
            this.createCustomerForm.controls.editionConfig.setValue(
              this.editionConfigOptions[0],
            );
        }
      },
    );

    this.createStandaloneCustomerForm.controls.email.valueChanges.subscribe(
      (value: string | null | undefined) => {
        if (value?.includes(' ')) {
          this.createStandaloneCustomerForm.controls.email.setValue(
            value.trim(),
          );
        }
      },
    );
  }
  ngAfterViewInit(): void {
    this.staticCreateForm.disable();
    this.createCustomerForm.controls.editionConfig.disable();
    this.ref.detectChanges();
  }

  staticCreateForm = new FormGroup({
    tenantUuid: new FormControl<string>(''),
    serviceVersion: new FormControl<string>(''),
  });

  createCustomerForm = new FormGroup({
    contractId: new FormControl<number | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$'),
      Validators.min(1),
    ]),
    customerId: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$'),
      Validators.maxLength(50),
    ]),
    licenseCount: new FormControl<number | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$'),
      Validators.min(1),
    ]),
    companyName: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(256),
    ]),
    edition: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^(Pro|Office|Standalone)$'),
    ]),
    editionConfig: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^(Companion|XS|XS-Lexware|S)$'),
    ]),
    isDomestic: new FormControl(false),
  });

  createStandaloneCustomerForm = new FormGroup({
    salutation: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    firstName: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    lastName: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(50),
    ]),
    street: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    zipCode: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(5),
    ]),
    city: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    country: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    businessPhone: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    mobilePhone: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(50),
    ]),
    email: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^.+@.+\\..+$'),
      Validators.maxLength(50),
    ]),
  });

  resetForm() {
    this.createCustomerForm.reset();
  }

  cancelButtonClicked() {
    this.cancelAction.emit();
  }

  private extractCustomerData(): CustomerRequest {
    const createCustomerFormControls = this.createCustomerForm.controls;
    const customerData: CustomerRequest = {
      contractId: Number(createCustomerFormControls.contractId.value ?? -1),
      customerId: createCustomerFormControls.customerId.value ?? '',
      licenseCount: Number(createCustomerFormControls.licenseCount.value ?? -1),
      edition: createCustomerFormControls.edition.value ?? '',
      editionConfig: createCustomerFormControls.editionConfig.value ?? '',
      isDomestic: createCustomerFormControls.isDomestic.value ?? false,
      companyName: createCustomerFormControls.companyName.value ?? '',
    };

    if (this.view === CommonCustomerView.EDIT) {
      return customerData;
    }

    const createStandaloneCustomerFormControls =
      this.createStandaloneCustomerForm.controls;

    return {
      ...customerData,
      salutation:
        createStandaloneCustomerFormControls.salutation.value ?? undefined,
      firstName:
        createStandaloneCustomerFormControls.firstName.value ?? undefined,
      lastName:
        createStandaloneCustomerFormControls.lastName.value ?? undefined,
      street: createStandaloneCustomerFormControls.street.value ?? undefined,
      zipCode: createStandaloneCustomerFormControls.zipCode.value ?? undefined,
      city: createStandaloneCustomerFormControls.city.value ?? undefined,
      country: createStandaloneCustomerFormControls.country.value ?? undefined,
      businessPhone:
        createStandaloneCustomerFormControls.businessPhone.value ?? undefined,
      mobilePhone:
        createStandaloneCustomerFormControls.mobilePhone.value ?? undefined,
      email: createStandaloneCustomerFormControls.email.value ?? undefined,
    };
  }

  async save() {
    if (!this.createCustomerForm.valid) {
      this.createCustomerForm.markAllAsTouched();
      return;
    }
    if (
      this.view === CommonCustomerView.CREATE &&
      this.createCustomerForm.controls.edition.value === 'Standalone' &&
      !this.createStandaloneCustomerForm.valid
    ) {
      this.createStandaloneCustomerForm.markAllAsTouched();
      return;
    }
    try {
      this.isLoading = true;
      this.isLoadingChange.emit(this.isLoading);
      const customerData = this.extractCustomerData();

      this.errorMessage = { show: false, message: '' };
      if (this.view === CommonCustomerView.CREATE) {
        await this.adminService.createCustomer(customerData);
        const customerId = this.createCustomerForm.controls.customerId.value;
        this.resetForm();
        customerId &&
          (await this.router.navigateByUrl('/customers/' + customerId));
      } else if (this.view === CommonCustomerView.EDIT) {
        const response = await this.adminService.updateCustomer(customerData);
        this.updateAction.emit(response);
        this.cancelAction.emit();
      }
    } catch (e: unknown) {
      const error = e as Error;
      this.createCustomerForm.markAllAsTouched();
      this.errorMessage = {
        show: true,
        message: error.message,
      };
    }
    this.isLoading = false;
    this.isLoadingChange.emit(this.isLoading);
  }

  onKeyDown() {
    console.log('key down'); // sonar issue Mouse events should have corresponding keyboard events
  }
}
