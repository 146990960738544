import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MigrationFilters } from '../../../../models/migration-filters';

@Component({
  selector: 'iwt-estatecloud-migration-search-customer',
  templateUrl: './migration-search-customer.component.html',
  styleUrls: ['./migration-search-customer.component.scss'],
})
export class MigrationSearchCustomerComponent {
  @Output() filterEvent = new EventEmitter<MigrationFilters>();

  filterFormGroup = new FormGroup({
    customerId: new FormControl<string | undefined>(undefined),
    companyName: new FormControl<string | undefined>(undefined),
    userName: new FormControl<string | undefined>(undefined),
  });

  searchFields() {
    this.filterEvent.emit(this.filterFormGroup.value);
  }

  onKeyDown() {
    console.log('key down'); // sonar issue Mouse events should have corresponding keyboard events
  }
}
