import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  EmproAccordionComponent,
  EmproButtonComponent,
  EmproCheckboxComponent,
  EmproDropdownComponent,
  EmproMultiSelectComponent,
  EmproRadioComponent,
  EmproSearchBarComponent,
  EmproSelectComponent,
  EmproSharedBadgeComponent,
  EmproSharedDatepickerComponent,
  EmproSharedIconComponent,
  EmproSharedInputComponent,
  EmproSharedModalComponent,
  EmproSharedPaginationComponent,
  EmproSharedTooltipComponent,
  EmproSpinnerComponent,
  EmproTextareaComponent,
  EmproTypographyComponent,
} from '@immosolve/empro-shared-ui';
import { EnvironmentService } from '../services/environment.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth.module';
import { CommonCustomerComponent } from './components/common-customer/common-customer.component';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { InputComponent } from './components/input/input.component';
import { ListCustomerComponent } from './components/list-customer/list-customer.component';
import { SearchComponent } from './components/list-customer/search/search.component';
import { ListUserComponent } from './components/list-user/list-user.component';
import { MigrationCustomerDetailsComponent } from './components/migration/migration-customer-details/migration-customer-details.component';
import { MigrationCustomerHeaderComponent } from './components/migration/migration-customer-header/migration-customer-header.component';
import { MigrationEditCustomerComponent } from './components/migration/migration-edit-customer/migration-edit-customer.component';
import { MigrationHeaderComponent } from './components/migration/migration-header/migration-header.component';
import { MigrationListCustomerComponent } from './components/migration/migration-list-customer/migration-list-customer.component';
import { MigrationSearchCustomerComponent } from './components/migration/migration-search-customer/migration-search-customer.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { CustomerDetailComponent } from './pages/customer-detail/customer-detail.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { LicenseComponent } from './pages/license/license.component';
import { LoginComponent } from './pages/login/login.component';
import { MigrationEstateSmartComponent } from './pages/migration-estateSmart/migration-estateSmart.component';

function initializeAppFactory(
  environmentService: EnvironmentService,
): () => Promise<void> {
  return () => environmentService.initConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CustomersComponent,
    CreateCustomerComponent,
    CustomerDetailComponent,
    MainLayoutComponent,
    CommonCustomerComponent,
    ListCustomerComponent,
    SearchComponent,
    InputComponent,
    PaginationComponent,
    ListUserComponent,
    MigrationEstateSmartComponent,
    MigrationHeaderComponent,
    MigrationSearchCustomerComponent,
    MigrationListCustomerComponent,
    MigrationCustomerHeaderComponent,
    MigrationCustomerDetailsComponent,
    MigrationEditCustomerComponent,
    LicenseComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AuthModule,
    // shared ui
    EmproAccordionComponent,
    EmproButtonComponent,
    EmproCheckboxComponent,
    EmproDropdownComponent,
    EmproMultiSelectComponent,
    EmproRadioComponent,
    EmproSelectComponent,
    EmproSharedBadgeComponent,
    EmproSharedDatepickerComponent,
    EmproSharedIconComponent,
    EmproSharedInputComponent,
    EmproSharedModalComponent,
    EmproSharedPaginationComponent,
    EmproSearchBarComponent,
    EmproSharedTooltipComponent,
    EmproSpinnerComponent,
    EmproTextareaComponent,
    EmproTypographyComponent,
    EmproSharedInputComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [EnvironmentService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
