<div class="mb-5 position-relative">
  <div class="relative mb-4" (keyup.enter)="searchFields()">
    <div class="flex justify-center rounded-2xl p-6 border">
      <div class="flex">
        <div class="flex gap-2 items-center justify-center">
          <form
            class="flex gap-2 items-center justify-center"
            [formGroup]="filterFormGroup"
          >
            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="customerId"
              class="input-filter"
              id="customerId"
              label="Kundennummer"
            ></iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="companyName"
              id="companyName"
              label="Firmenname"
            ></iwt-estatecloud-input>

            <iwt-estatecloud-input
              [form]="filterFormGroup"
              controlName="userName"
              class="input-filter"
              id="userName"
              label="Benutzername"
            ></iwt-estatecloud-input>
          </form>
          <empro-button
            (click)="searchFields()"
            [fill]="'solid'"
            [variant]="'primary'"
            class="font-medium"
            onKeyDown="onKeyDown()"
          >
            Suchen
          </empro-button>
        </div>
      </div>
    </div>
  </div>
</div>
