import { Component, Input } from '@angular/core';
import { UserResponse } from '../../../models/user-response';
import { UsersResponse } from '../../../models/users-response';
import { AdminService } from '../../../services/admin.service';
import { originalOrder } from '../../common/utils';

@Component({
  selector: 'iwt-estatecloud-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css'],
})
export class ListUserComponent {
  public originalOrder = originalOrder;
  public COLUMNS: Record<string, string> = {
    email: 'E-mail',
    userUuid: 'UserUuid',
    enabled: 'Account-Status',
    lastLoginTime: 'Letzte Anmeldung',
    lastLoginChallanges: 'Login-Status',
    resendEmail: 'Aktionen',
  };

  constructor(public adminService: AdminService) {}

  @Input() users!: UsersResponse;
  @Input() tenantUuid?: string;

  openModal: boolean = false;
  currentUser: UserResponse = new UserResponse();
  usersWhereResentHaveBeenClickedAllready: UserResponse[] = [];
  messageShow: boolean = false;
  messageType: string = 'success';
  message: string = '';

  async closeModal(event: string | undefined) {
    if (event === 'resendWelcomeMail') {
      try {
        await this.resendEmail(this.currentUser);
        this.showMessage(
          'success',
          'E-Mail wurde an den Kunden verschickt: ' + this.currentUser.email,
        );
      } catch (e: unknown) {
        const error = e as Error;
        this.showMessage('error', error.message);
      }
    }
    this.openModal = false;
  }

  async showResendEmailModal(user: UserResponse) {
    if (!this.tenantUuid) {
      throw new Error('TenantUuid not set!');
    }
    if (user.email === '') {
      return;
    }

    this.currentUser = user;
    this.openModal = true;
  }

  async resendEmail(user: UserResponse): Promise<void> {
    if (!this.tenantUuid) {
      throw new Error('TenantUuid not set!');
    }
    await this.adminService.resendEmail(this.tenantUuid, user.userUuid);

    this.usersWhereResentHaveBeenClickedAllready.push(user);
  }

  showMessage = (type: string, message: string) => {
    switch (type) {
      case 'success':
        this.messageType = 'success';
        break;

      case 'error':
        this.messageType = 'error';
        break;
    }
    this.messageShow = true;
    this.message = message;
  };

  onKeyDown() {
    console.log('key down'); // sonar issue Mouse events should have corresponding keyboard events
  }
}
