import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Filters } from '../../../../models/filters';

@Component({
  selector: 'iwt-estatecloud-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Output() filterEvent = new EventEmitter<Filters>();
  @Output() resetFormEvent = new EventEmitter<Filters>();
  openExtendedFilter: boolean = true;
  filterFormGroup = new FormGroup({
    contractId: new FormControl<number | undefined>(undefined),
    tenantUuid: new FormControl<string | undefined>(undefined),
    customerId: new FormControl<string | undefined>(undefined),
    companyName: new FormControl<string | undefined>(undefined),
    edition: new FormControl<string | undefined>(undefined),
    editionConfig: new FormControl<string | undefined>(undefined),
    serviceVersion: new FormControl<string | undefined>(undefined),
    isDomestic: new FormControl<boolean | undefined>(undefined),
  });

  toggleFilterModal() {
    this.openExtendedFilter = !this.openExtendedFilter;
  }

  searchFields() {
    this.filterEvent.emit(this.filterFormGroup.value);
  }

  resetForm() {
    this.filterFormGroup.reset();
  }

  onKeyDown() {
    console.log('key down'); // sonar issue Mouse events should have corresponding keyboard events
  }
}
